<template>
  <LayoutElementsList :elements="elements" is-summary-view />
</template>

<script>
import LayoutElementsList from './LayoutElementsList'
import { mapGetters } from 'vuex'
import fuzzyMatch from '@utils/fuzzyMatch'

export default {
  name: 'LayoutEditorElements',
  components: {
    LayoutElementsList,
  },
  props: {
    location: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('menus', ['getMenu']),
    ...mapGetters('sections', ['getSection']),
    ...mapGetters('items', ['getItem']),
    ...mapGetters('layoutEditor', ['elementSearch']),
    elements() {
      let elements = this.menuElements
      elements.push(
        this.formatElement('Site Title', 'Site', this.location.site.id, {
          text: '[ Site Title ] ' + this.location.site.title,
        })
      )
      elements.push(
        this.formatElement('Hours', 'Location', this.location.id, {
          text: '[ Hours ] ' + this.location.hours_for_humans,
        })
      )
      elements.push(
        this.formatElement(
          'Address',
          'Contactinfo',
          this.location.contactinfo.id,
          {
            text: '[ Address ] ' + this.getAddress(this.location.contactinfo),
          }
        )
      )
      elements.push(
        this.formatElement(
          'Phone',
          'Contactinfo',
          this.location.contactinfo.id,
          { text: '[ Phone ] ' + this.location.contactinfo.phone_number }
        )
      )

      if (this.elementSearch) {
        let targetKey = (el) => el.title
        elements = fuzzyMatch(elements, targetKey, this.elementSearch)
      }

      return elements
    },
    menuElements() {
      let elements = []
      this.location.menus.forEach((menuId) => {
        let menu = this.getMenu(menuId)
        elements.push(
          this.formatElement(menu.title, 'Menu', menuId, {
            text: `[ Menu Title ] ${menu.title}`,
            data: menu,
          })
        )
        menu.sections.forEach((sectionId) => {
          let section = this.getSection(sectionId)
          let elTitle = section.title
          if (
            !elTitle.length &&
            ['hide_title', 'condensed'].includes(section.section_type)
          ) {
            let firstItem = this.getItem(section.items_json[0])
            elTitle = firstItem.title
            if (section.is_callout) {
              elTitle = `[ Callout ] ${elTitle}`
            }
          } else {
            elTitle = `[ Section ] ${elTitle}`
          }
          elements.push(
            this.formatElement(elTitle, 'MenuSection', section.id, {
              text: elTitle,
              data: section,
            })
          )
        })
      })
      return elements
    },
  },
  methods: {
    formatElement(title, model, id, content) {
      return {
        title,
        related_model_type: model,
        related_model_id: id,
        type: 'related',
        content,
      }
    },
    getAddress(contactinfo) {
      return `${contactinfo.street_1}${
        contactinfo.street_2 ? ' ' + contactinfo.street_2 : ''
      } ${contactinfo.city}, ${contactinfo.state} ${contactinfo.zip}`
    },
  },
}
</script>
